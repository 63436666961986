import React from "react"
import { graphql } from "gatsby"


import '../styles/prism-theme.css'
import '../styles/home.css'
import './blog.css'
import '../styles/normalize.css'
import '../styles/skeleton.css'
import {FiArrowUp} from 'react-icons/fi'

import Header from "../components/Header"
import Footer from "../components/Footer"
import MdxBlock from "../components/mdx"
import Meta from "../components/Meta"
import Socials from "../components/Socials"



function Template({ data: { mdx } }) {

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })
  }

  if (mdx.frontmatter.layout === "post") {
    return (
      <div className="container main">
        <title>{mdx.frontmatter.title}</title>
        <Meta></Meta>
        <Header selected="articles"></Header>
        <div className="blog-post-container">
        <div className="blog-post">
          <h1>{mdx.frontmatter.title}</h1>
          <h6>{mdx.frontmatter.date}</h6>
          <div
            className="blog-post-content"
          />
          <MdxBlock mdx={mdx}></MdxBlock>
        </div>
      </div>
      <div className="top">
        <button onClick={handleClick}>
          <FiArrowUp/>
          <br/>
          BACK TO TOP
        </button>
      </div>

      <Socials></Socials>
      <Footer></Footer>
    </div>
    )
  }
  else {
    return (
      <div className="container main dark">
        <title>{mdx.frontmatter.title}</title>
        <Header></Header>
        <div className="blog-post-container">
        <div className="blog-post">
          <div className="blog-post-content">
            <MdxBlock mdx={mdx}></MdxBlock>
          </div>
        </div>
      </div>
      <div className="top">
        <button onClick={handleClick}>
          ↑
          <br/>
          BACK TO TOP
        </button>
      </div>

      <Socials></Socials>
      <Footer></Footer>
    </div>
    )
  }
}

export default Template

export const pageQuery = graphql`
  query($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        layout
        pagetype
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`